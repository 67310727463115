<div class="home-page">
    <div class="home-page__title-section">
        <div class="home-page__title-section__title">Eddington Number Badge</div>
        <div class="home-page__title-section__sub">Get automatic updates on your Strava activities about your Eddington number progress</div>
        <img class="home-page__title-section__img" src="./../../../assets/img/screenshot.png">
    </div>
    <div class="home-page__connect-strava">
        <div class="home-page__connect-strava__title">Connect with Strava</div>
        <div class="home-page__connect-strava__description">By connecting with Strava Eddington number updates on your Strava activities will automatically get appended to the activity description (existing descriptions will not be replaced, only appended to).</div>
        <app-authorization-link></app-authorization-link>
    </div>
    <div class="home-page__eddington-explanation">
        <div class="home-page__eddington-explanation__title">What is my Eddington number?</div>
        <div class="home-page__eddington-explanation__definition">The Eddington number is defined as the largest integer E, where you have cycled (or some other sport such as running, walking or swimming) at least E kilometers on at least E days.</div>
        <div class="home-page__eddington-explanation__example__cycling"><strong>Example:</strong> if your Eddington number for <strong>cycling</strong> is 85 it means you have ridden 85 rides of at least 85 kilometers.</div>
        <div class="home-page__eddington-explanation__example__running"><strong>Example:</strong> if your Eddington number for <strong>running</strong> is 15 it means you have ran 15 runs of at least 15 kilometers.</div>
    </div>
    <div class="home-page__data-usage">
        <div class="home-page__data-usage__title">Data usage</div>
        <div class="home-page__data-usage__description">
            The Eddington Number Badge app only stores and uses data to calculate your Eddington number. Data is never sold or shared with third parties. We store the following data: 1) Athlete: Athlete ID, Measurement preference (miles or kms), City, State and Country of residence, 2) Activity: Activity ID, Distance, Type, Startdate
        </div>
    </div>
    <div class="home-page__unconnect-strava">
        <div class="home-page__unconnect-strava__title">Unconnect Strava</div>
        <div class="home-page__unconnect-strava__description">To revoke access for Eddington Number Badge to your Strava activities, you should visit your Strava Profile <a href="https://www.strava.com/settings/apps">settings</a></div>
    </div>
    <div>
        <img [src]="'assets/img/api_logo_pwrdBy_strava_horiz_light.png'">
    </div>
</div>