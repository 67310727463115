import { Component } from '@angular/core';
import { AuthorizationLinkComponent } from '../authorization-link/authorization-link.component';

@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [AuthorizationLinkComponent],
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.scss'
})
export class HomePageComponent {

}
