import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-authorization-link',
  standalone: true,
  imports: [],
  templateUrl: './authorization-link.component.html',
  styleUrl: './authorization-link.component.scss'
})
export class AuthorizationLinkComponent {
  environment = environment;

  constructor() {}
}
