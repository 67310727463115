<div class="app-authorization-result">
    @if (authorizationSucceeded === false) {
        <div class="app-authorization-result__failure">
            <div class="app-authorization-result__failure__title">Connecting with Strava failed</div>
            @if (authorizationFailureReason === 'missing_scopes') {
                <div>To connect with Strava you need to allow the Eddington Badge App to 1) View your complete profile, 2) View data about your private activities and 3) Upload your activities from Eddington Badge App to Strava (to update descriptions)</div>
            }
        </div>
    }
    @if (registrationResult$ | async) {
        <div class="app-authorization-result__success">
            <div class="app-authorization-result__success__title">Connecting with Strava done!</div>
            The Eddington Badge App is now connected to Strava. Calculating your Eddington number will now start in the background.
        </div>
    } 
</div>
