import { HttpClient } from "@angular/common/http";
import { ApiRoutes } from "../constants/api-routes";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class RegistrationService {
    constructor(private http: HttpClient) {}

    register(authorizationCode: string) {
        return this.http.post(`${ApiRoutes.baseUrl}/${ApiRoutes.register}`, { authorizationCode, enableImperial: true, enableMetric: true });
    }
}