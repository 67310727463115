import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StravaScopes } from '../../core/constants/strava-scopes';
import { RegistrationService } from '../../core/services/registration.service';
import { Observable, of } from 'rxjs';
import { AsyncPipe, JsonPipe } from '@angular/common';

@Component({
  selector: 'app-authorization-result',
  standalone: true,
  imports: [AsyncPipe, JsonPipe],
  templateUrl: './authorization-result.component.html',
  styleUrl: './authorization-result.component.scss'
})
export class AuthorizationResultComponent implements OnInit {
  registrationResult$?: Observable<Object>;
  authorizationSucceeded?: boolean;
  authorizationFailureReason?: string;  

  constructor(private route: ActivatedRoute, private registrationService: RegistrationService) {}

  ngOnInit(): void {
    const authorizationCode = this.route.snapshot.queryParams['code'];
    const scopes = (this.route.snapshot.queryParams['scope'] as string)?.split(',');

    if (!scopes.includes(StravaScopes.read) || !scopes.includes(StravaScopes.activityReadAll) || !scopes.includes(StravaScopes.activityWrite)) {
      this.authorizationSucceeded = false;
      this.authorizationFailureReason = "missing_scopes";
    }

    else if (authorizationCode === undefined) {
      this.authorizationSucceeded = false;
      this.authorizationFailureReason = "missing_authorization_code";
    }

    else {
      this.registrationResult$ = this.registrationService.register(authorizationCode);
    }
  }
}
